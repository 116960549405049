<template>
  <v5-template :url="url" />
</template>

<script>

export default {
  components: {
    V5Template: () => import('./v5-template.vue')
  },
  computed: {
    url () {
      return `${new URL(document.location).origin}/v5/index.html#/run`
    }
  }
}
</script>
